import React from 'react'
import { motion } from "framer-motion";
import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';


const Posts = ({posts, loading}) => {
    if(loading) {
        return <h2> </h2>
    }
    return (
        /*
       <ul>
           {posts.map(post => (
               <li key={post.ProdEID}>
                   {post.PrName}
               </li>
           ))}
       </ul>
       */

        
        <div className="max-w-2xl bg-transparent mx-auto h-auto sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="grid grid-cols-3 gap-y-10 sm:grid-cols-4 gap-x-6 lg:grid-cols-5 xl:grid-cols-6 xl:gap-x-4">
            {posts.map(post => (
            /*<motion.a 
            whileHover={{ scale: 1.02 }}
            transition={{
                type: "spring",
                stiffness: 260,
                damping: 20
            }}*/
            <Link
      to={'/product'}
      state= {{jsonUrl: post.Link}}
            key={post.ProdEID} className="group flex flex-col border-2 border-transparent hover:shadow-custom rounded-lg p-2">
                <div className="w-full aspect-w-1 aspect-h-1 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                <img
                    src={post.ThumbPic}
                    alt={post.PrName}
                    className="mx-auto w-auto h-32 self-center justify-object-center object-center object-contain"
                />
                </div>
                
                <h3 className="mt-4 text-md text-gray-700" >{post.PrName}</h3>
                
                <div>
                <p className="mt-1 text-sm font-semibold text-gray-900">${post.Prc}</p>
                </div>
            </Link>/*</motion.a>*/
            ))}
        </div>
        </div>
        
    )
}

export default Posts
