import React from 'react'
import itemDefault from '../shirts.json';
import {useState, useEffect} from 'react';
import { red } from '@material-ui/core/colors';
import { useLocation } from 'react-router';


const Product = () => {

    
    //const [jsonUrl, setJsonUrl] = useState('https://www.attentionpromo.com/api/products/784586373');
    const location = useLocation();
    const jsonUrl  = location.state?.jsonUrl;
    const [Item, setItem] = useState(itemDefault);
    
    const capitalizeFirstLetter  = string => {
        return string[0].toUpperCase() + string.slice(1);
    }

    useEffect(() => {
        console.log("inside useEffect")
        console.log(jsonUrl);
        async function getJsonData() {
            

            
            try {
                const response = await fetch(jsonUrl);
                const responseJson = await response.json();
                setItem(responseJson);
            } catch (error) {
                console.error(error);
            }
        

         }
        getJsonData();
      }, [jsonUrl])


      const [qtySelected, setQtySelected] = useState(0);
    const [optionSelected, setOptionSelected] = useState(0);

    const setQty = index => {
        setQtySelected(index);
      };
    
      const setOption = index => {
        setOptionSelected(index);
      };


      const priceAction = () => {

      }

      const priceTotal = () => {
        return (Item.Qty[qtySelected] + Item.Prc[qtySelected]);
      };
      const testFunc = () => {
        let count = 0;
        let newArr = Item.Qty.filter(function (e) {
            if (e > 0) {
                count = count + 1;
            }
            });
            return count;
    }
        

    const quantity = Item.Qty.map((number, index) =>  {
        const linkContent = Item.Prc[index];
        
        if(number != 0){
          
        return (
            <div className="m-1 flex w-32 h-16 float-left bg-white rounded-xl items-center border hoverr" style={{ border: index==qtySelected ? '1px solid black' : '1px solid white' }} onClick={() => setQty(index)}>
            <div className="flex w-1/2 h-3/4 font-semibold text-center justify-center items-center hover:border-2 border-r-2">{number}</div>
            <div className="flex w-1/2 font-regular text-sm text-center bg-transparent justify-center items-center leading-tight">${linkContent}<br/>Each</div>
            </div>
            );
        }   
    });

/* ******************** */

/* BEFORE
    const valtPrc = prc => {
        if(prc !== undefined) {
            return (
            prc.slice(0, testFunc()).map((price, index)  => (
                <td className="w-16 text-center border">{price}</td>
            ))
            );
        }
    };
*/

const lent = Item.Qty.length;

const valtPrc = prc => {
    if(prc !== undefined) {
        return (
        prc.slice(0, testFunc()).map((price, index)  => (
            <td className="w-16 text-center border">{price}</td>
        ))
        );
    }
    
    else {
        return (
            Item.Qty.map((number, index)  => (
                
                number !== '0' ? <td className="w-16 text-center border">0.00</td> : null
                
            ))
            );
    }
};


    const valuesArray = val => {
        if(val !== undefined){
        return (
            <React.Fragment>
            {
        val.map((valt , index)  => (
            
                <tr className="bg-white">
                <td className="justify-items-center w-1/12 border text-center">
                <input className="margined focus:ring-indigo-400 focus:ring-opacity-25 border"  onChange={priceAction()}
                 type="checkbox" /> 
                 </td>
                <td className="border border-gray-200 w-5/12 p-2">{valt.Value}</td>
                {valtPrc(valt.Prc)}
                </tr>
        ))
        }
        </React.Fragment>
        );
    }
    
      };

    const addOptions = Item.Options.map((option, index) => {
        return (                   
            <div>
            
            <h1 className="font-medium p-2 py-2 bg-attentionblue text-white">{option.Name}</h1>
            <table className="table-fixed w-full mb-5">
            {valuesArray(option.Values)}
            </table>
            </div>

            );
    });

/* ******************** */

    const price = Item.Prc.map((number) =>  {
        if(number !== ''){
        return <td className="w-16 font-medium text-center border">{number}</td>
        }
    });

    const qtyTable = Item.Qty.map((number, index) => {
        if(number !== '0') {
            
            
        return (
            
            <td className="w-16 font-medium h-10 text-center border">{number}</td>
            
          
        );
        }
        
    });



    if(Item != itemDefault){
    return (    
        <div className="font-serif grid grid-cols-1 lg:grid-cols-5 h-screen">
            <div className="p-4 text-left col-span-1 lg:col-span-2  place-items-center">
                <img className="mx-auto w-45" src={Item.Pics[optionSelected].URL}/>
                <div className=" md:w-4/5 mx-auto pt-5 ">
                    <p className="font-semibold text-2xl mb-1">{Item.PrName}</p>
                    <span className="border-red-c-1 font-medium text-med pl-2 pr-2 rounded-full">SPC# {Item.SPC}</span>
                    <p className="font-regular text-sm pt-2">{Item.Description}</p>
                    <div className=" pr-2 mt-2">
                        <p className="font-regular text-sm pt-2">Production Time: {Item.ProdTime}</p>
                        <p className="font-regular text-sm">Package: {Item.Package}</p>
                        <p className="font-regular text-sm">Imprint Area: {Item.ImprintArea}</p>
                        <p className="font-regular text-sm">Decoration Method: {Item.DecorationMethod}</p>
                    </div>
                    {/* <p>{Item.Themes}</p> */}
                </div>
            </div>
            <div className="bg-custom-100 inline-block h-full px-14 col-span-1 lg:col-span-3">
            

            
            <p className="font-semibold pt-4 text-md ml-2">Option: <span className='font-normal'> {Item.Pics[optionSelected].Caption.charAt(0).toUpperCase() + Item.Pics[optionSelected].Caption.slice(1).toLowerCase()}</span></p>
            
            {/* Item.Pics[optionSelected].Caption.toLowerCase() */}
            <p className="inline-block">
                {Item.Pics.map((pic, index) => {
                    if(pic.Caption == ''){}
                    else{
                    return <img className="hovering border-2 border-white max-h-20 max-w-20 float-left p-2 bg-white rounded-xl m-1" onClick={() => setOption(index)} style={{ border: index==optionSelected ? '2px solid black' : null }} src={pic.URL}/>
                    }
                })}
            </p>

            {/* onClick={() => setOption(index)} style={{ border: index==optionSelected ? '2px solid black' : '2px solid white' }} */}

            <table className="w-full mt-4 bg-white mb-6">
                <tr>
                    <td className="w-6/12 font-medium text-left border p-2">Quantity</td>
                    {qtyTable}
                </tr>
                <tr>
                    <td className="w-6/12 text-left font-medium border p-2">Price (Each)</td>
                    {price}
                </tr>
            </table>
            
            {/*
                <p className="font-medium">{Item.Pics[optionSelected].Caption.toLowerCase()}</p>
            <p>{Item.Pics[optionSelected].Caption}</p>
            
            <div className="inline-block w-full float-left mb-5">
                <p className="font-semibold text-lg ml-2 mt-5">Quantity</p>
                {quantity}
                
                
            </div>
            */}
            
            


            {/* <p className="font-semibold float-left block text-lg ml-2">Additional Options</p> */}
            {addOptions}

            {/* This is the quantity input
                 <input className="p-2 w-2/12 h-12" type='number'></input> */}
            {/*
            <p className="inline-block w-full float-left font-semibold text-lg ml-2 mt-5">Price</p>
            <p className="inline-block float-left font-semibold text-3xl ml-2">${(Item.Qty[qtySelected] * Item.Prc[qtySelected]).toFixed(2)}</p>
            */}
            </div>
        
        </div>
    ) /*end return*/
        }
        else {
            return (
                <div>LOADING</div>
            )
        }
    
}

export default Product
