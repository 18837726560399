import React from 'react'
import { SearchIcon} from '@heroicons/react/solid'
import { useState } from 'react';


const Search = ({searchBox, loading}) => {
    const [searchInput, setSearchInput] = useState('');

    const handleKeypress = e => {      
        if (e.key === 'Enter') {      
            searchBox(searchInput);   
        }  
    };



    return (
        
    <div className="h-16 mt-4 rounded-md bg-gray-100 p-3 flex mx-auto max-w-screen-xl">
        <span className="w-auto flex justify-end items-center text-gray-500 p-2">
            <SearchIcon className="h-7 w-7"/>
        </span>
        <input className="w-full bg-gray-100 rounded p-2 focus:outline-none focus:border-gray-200 mx-1" value={searchInput} type="text" onKeyPress={handleKeypress}  onInput={e => setSearchInput(e.target.value)} placeholder="Try 'Pens'" onFocus={(e) => e.target.placeholder = ""} ></input>
        
        <button className="bg-red-600 hover:bg-red-400 rounded text-white fixed-width p-2 pl-6 pr-6" onClick={() => searchBox(searchInput)}>
                {loading ? 
                
                <svg
                className="animate-spin block ml-3 mr-3 h-5 w-5 mx-auto text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 25 25"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="5"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg> 
              :
              <p className="font-semibold text-sm">Search</p> 
              }
        </button>
    </div>

    )
}

export default Search
