import './App.css';
import Search from './Components/Search';
//import Products from './PRODUCTS.json';
import {useState, useEffect} from 'react';
import Pagination from './Components/Pagination';
import Posts from './Components/Posts';
import Product from './Components/Product';
//import { Link } from '@material-ui/core';
import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';




function App() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(24);
  const [input, setInput] = useState(null);
  const [showPagination, setShowPagination] = useState(false);

/*
  useEffect(() => {
    fetch('https://attentionpromo.com/api/products?Keywords=Flashlight')
        .then(response => response.json())
        .then(data => setPosts(data));
}, []);
*/


useEffect(() => {
  
  async function getData() {
    if(input != null) {
    setCurrentPage(1);
    setShowPagination(false);
    setLoading(true);
    const url = 'https://www.attentionpromo.com/api/products?Keywords=' + input;
    const response = await fetch(url);
    const data = await response.json();
    setPosts(data);
    setShowPagination(true);
    setLoading(false);
    }
  }
  getData();
}, [input]);



const searchBox = (text) => {
  setInput(text);
  
};

  //get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  //change page
  const paginate = (number) => {
    setCurrentPage(number);
   
  };

  // Change page
const paginateFront = () => {
  if(currentPage < 5) {
  setCurrentPage(currentPage + 1);
  }
}
const paginateBack = () => {
  if(currentPage > 1) {
  setCurrentPage(currentPage - 1);
  }
}

//<Pagination postsPerPage={postsPerPage} totalPosts={posts.length} paginate={paginate} paginateBack={paginateBack} paginateFront={paginateFront} />

  return (
    <Router>
    
    <Routes>
    <Route exact path="/product" element={<Product/>}/>
    <Route exact path="/" element={
    <div className="container mx-auto px-4">
      <Search searchBox={searchBox} loading={loading}/>
      <Posts posts={currentPosts} loading={loading} />
      {showPagination ? <Pagination postsPerPage={postsPerPage} totalPosts={posts.length} paginate={paginate} currentPage={currentPage} paginateBack={paginateBack} paginateFront={paginateFront} /> : null}
    </div>
  }/>
    </Routes>
    </Router>
  )
}

export default App;

