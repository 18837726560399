import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/solid'

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage, paginateBack, paginateFront}) => {
  const pageNumbers = [];



  if(totalPosts > 120) {
    totalPosts = 120;
  }
  
  for(let i=1; i <= Math.ceil(totalPosts/postsPerPage); i++) {
    pageNumbers.push(i);
  }

    return (
      /*
      <ul>
        {pageNumbers.map(number => (
          <li key={number}>
          <a onClick={() => paginate(number)} href="!#">
            {number}
          </a>
          </li>
        ))}
      </ul>
      */
      <div className="bg-white px-4 py-3 flex items-center justify-between  sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <a
          href="#"
          onClick={() => {
            paginateBack();
          }}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </a>
        <a
          href="#"
          onClick={() => {
            paginateFront();
          }}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        
      <div>
          <p className="text-sm text-gray-700">
            Showing 
            <span className="font-medium"> {totalPosts}</span> results
          </p>
        </div>
        
        <div>
          <nav className="relative z-0 inline-flex rounded-full shadow-sm -space-x-px" aria-label="Pagination">
            <a
              href="#"
              onClick={() => {
                paginateBack();
              }}
              className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}

            {pageNumbers.map((number) => (
              <a
              key={number}
              onClick={() => paginate(number)}
              href="#"
              className={(currentPage === number) ? "cursor-pointer bg-gray-500 border-gray-500 hover:bg-gray-400  text-white  relative inline-flex items-center px-4 py-2 border text-sm font-medium" : "cursor-pointer bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"}
              //className="cursor-pointer bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
              >
                {number}
              </a>
            ))}
            
            <a
              href="#"
              onClick={() => {
                paginateFront();
              }}
              className="cursor-pointer relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
      )
}

export default Pagination
